import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PageLayout from "../../client_customizations/components/PageLayout/PageLayout";

const FourOhFour = ({ ip, uuid }) => {
  useEffect(() => {
    document.title = "[#/PAGE_TITILE/#]";
  });

  return (
    <PageLayout ip={ip} uuid={uuid}>
      <section className="container" style={{ minHeight: "500px" }}>
        <div className="row">
          <div className="col-sm-12">
            <div className="SimpleCopyPageHeader">
              <p className="h3">Page Not Found</p>
              <p className="">
                This page does not exist. If you arrived here by error, please
                go back to the <Link to="/" style={{textDecoration: 'underline'}}>homepage</Link>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default FourOhFour;

FourOhFour.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string
};
