import React, { useContext } from "react";
import {useIntl} from 'react-intl';
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import Select from "../../shared/InputElements/Select";
import { FormatCarName } from "../../../utils/Helpers/Format";
import sortEVs from "../../../functions/vehicle/Sort/sortEVs"

const SelectVehicleIdForIncentives = ({
  electricVehicles,
  id = "select-vehicle-id-for-incentives",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "incentives.whichVehicle", defaultMessage: "Which vehicle do you plan to purchase?"}) : "Which vehicle do you plan to purchase?"
  const disabled = false;
  const isLarge = false;

  electricVehicles = sortEVs(electricVehicles, "alphabetical", "asc")

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleIdForIncentives")}
      optionValues={["", ...electricVehicles.map(ev => ev.vehicle_id)]}
      optionDataValues={["", ...electricVehicles.map(ev => ev.handle)]}
      optionNames={[
        "",
        ...electricVehicles.map(
          ev => `${FormatCarName(ev)}`
        )
      ]}
      label={label}
      disabled={disabled}
      isLarge={isLarge}
      handler={e => {
        const vehicleHandle = ((e.target.selectedIndex) > 0) ?
          electricVehicles[e.target.selectedIndex - 1].handle : "";
        userPrefs.set({
          vehicleHandleForIncentives: vehicleHandle,
          vehicleIdForIncentives: e.target.value
        });
      }}
      {...rest}
    />
  );
};

export default SelectVehicleIdForIncentives;

SelectVehicleIdForIncentives.propTypes = {
  electricVehicles: PropTypes.array,
  id: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLarge: PropTypes.bool
};
