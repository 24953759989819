import React from "react";
import PropTypes from "prop-types";
import IncentiveCard from "./../IncentiveCard/IncentiveCard";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";

const IncentiveCatalog = ({ incentives, titleText }) => {
  if (!incentives) return <LoadingSpinner />;
  
  const renderCards = incentives
    ? incentives.map((incentive, index) => {
        return <IncentiveCard incentive={incentive} key={index} />;
      })
    : null;

  return (
    <div className="row mt-3 mb-3">
      <div className="col-sm-12">
        {titleText && <p className="h3 text-left">{titleText}</p>}
        <div className="d-flex justify-content-start flex-wrap">
          {renderCards}
        </div>
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool
};
