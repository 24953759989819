import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../LinkCard/LinkCard"

const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  return (
    <>
      <section 
        className="container-fluid homepage-jumbotron"
        style={{
        backgroundImage: "url(" + homePageBannerImage + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
        padding: 0}}
      >
      <div className="big-promise">
        <h1 className="mt-4">EVolve</h1>
        <p className="mt-4 h1">
          Drive electric and cruise into savings.
        </p>
      </div>
      </section>
      <section className="container-fluid" id="homepage-cards-container">
        <div className="row homepage-cards-container">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="DISCOVER_INCENTIVES" />
              <LinkCard type="LOCATE_CHARGING_STATIONS" />
              <LinkCard type="HOME_CHARGERS" />
              <LinkCard type="IID" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}
