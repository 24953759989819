import React, { useContext } from "react";
import {useIntl} from 'react-intl';
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import ButtonGroup from "../../shared/InputElements/ButtonGroup";
import Select from "../../shared/InputElements/Select";

const ChooseTaxFilingStatus = ({
  id = "tax-filing-status",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "incentives.taxFilingStatus", defaultMessage: "Tax Filing Status"}) : "Tax Filing Status";


  let singleLabel = intl.formatMessage ? intl.formatMessage({ id: "incentives.single", defaultMessage: "Single"}) : "Single";
  let marriedLabel = intl.formatMessage ? intl.formatMessage({ id: "incentives.married", defaultMessage: "Married"}) : "Married";
  let marriedDataLabel = "joint";
  let headOfHouseLabel = intl.formatMessage ? intl.formatMessage({ id: "incentives.headOfHousehold", defaultMessage: "Head of Household"}) : "Head of Household";
  let headOfHouseDataLabel = "head_of_household";

  return (
    <>
      <div className="d-none d-md-block">
        <ButtonGroup
          id={id}
          value={userPrefs.get("taxFilingStatus")}
          label={label}
          optionNames={[singleLabel, marriedLabel, headOfHouseLabel]}
          optionValues={[singleLabel.toLowerCase(), marriedDataLabel, headOfHouseDataLabel]}
          handler={(e, selected) =>
            userPrefs.set({ taxFilingStatus: selected })
          }
          {...rest}
        />
      </div>
      <div className="d-block d-md-none">
        <Select
          id={id}
          value={userPrefs.get("taxFilingStatus")}
          label={label}
          optionNames={["Single", "Married", "Head of Household"]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={e => userPrefs.set({ taxFilingStatus: e.target.value })}
          {...rest}
        />
      </div>
    </>
  );
};

export default ChooseTaxFilingStatus;

ChooseTaxFilingStatus.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string
};