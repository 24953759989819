import React, { useContext } from "react";
import {useIntl} from 'react-intl';
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import ButtonGroup from "../../shared/InputElements/ButtonGroup";

const ChooseCanTurnInClunker = ({
  id = "can-turn-in-clunker",
  incentives,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "incentives.planClunker", defaultMessage: "Are you planning to turn in a clunker?"}) : "Are you planning to turn in a clunker?";

  let yesLabel = intl.formatMessage ? intl.formatMessage({ id: "yes", defaultMessage: "Yes"}) : "Yes";
  let noLabel = intl.formatMessage ? intl.formatMessage({ id: "no", defaultMessage: "No"}) : "No";

  return (
    <ButtonGroup
      id={id}
      value={userPrefs.get("canTurnInClunker").toString()}
      label={label}
      optionNames={[yesLabel, noLabel]}
      optionValues={["true", "false"]}
      handler={(e, selected) =>
        userPrefs.set({ canTurnInClunker: selected === "true" })
      }
      {...rest}
    />
  );
};

export default ChooseCanTurnInClunker;

ChooseCanTurnInClunker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  incentives: PropTypes.array
};