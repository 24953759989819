import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import TwitterIcon from "../../../assets/images/icons/Twitter.png"
import FacebookIcon from "../../../assets/images/icons/Facebook.png"
import YouTubeIcon from "../../../assets/images/icons/YouTube.png"
import LinkedInIcon from "../../../assets/images/icons/LinkedIn.png"
import { FormattedMessage } from 'react-intl';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = () => {

  const socialIcons = (
    <div className="social-icons mb-2">
      <a
        href="https://twitter.com/IIDatWork"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Twitter Icon" src={TwitterIcon} />
      </a>
      <a
        href="https://www.facebook.com/IIDEnergy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Facebook Icon" src={FacebookIcon} />
      </a>
      <a
        href="https://www.linkedin.com/company/imperial-irrigation-district/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="LinkedIn Icon" src={LinkedInIcon} />
      </a>
      <a
        href="https://www.youtube.com/IIDvideo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="YouTube Icon" src={YouTubeIcon} />
      </a>
    </div>
  );

  return (
    <>
      <div className="Footer">
        <div className="container pt-2 pb-4">
          <div className="social-media-row">
            {socialIcons}
          </div>
          <div className="row zr-row">
            <Link to="/" className="footer-border pad-right">Home</Link>
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles" className="footer-border pad-right pad-left">
                <FormattedMessage
                  id="electricVehicles"
                  defaultMessage="Electric Vehicles"
                  description="Electric Vehicles Footer"
                  values={{
                    electricVehicles: process.env.REACT_APP_PAGES_VEHICLES_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
              <Link to="/compare-vehicles" className="footer-border pad-right pad-left">
                <FormattedMessage
                  id="compareVehicles"
                  defaultMessage="Compare Vehicles"
                  description="Compare Vehicles Footer"
                  values={{
                    compareVehicles: process.env.REACT_APP_PAGES_COMPARE_TITLE
                  }}
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
              <Link to="/incentives" className="footer-border pad-right pad-left">
                <FormattedMessage
                  id="incentives"
                  defaultMessage="Incentives"
                  description="Incentives Footer"
                  values={{
                    incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                  }}
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
              <Link to="/charging-stations" className="footer-border pad-right pad-left">
                <FormattedMessage
                  id="chargingStations"
                  defaultMessage="Charging Stations"
                  description="Charging Stations Footer"
                  values={{
                    chargingStations: process.env.REACT_APP_PAGES_CHARGING_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
              <Link to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK} className="footer-border pad-right pad-left">
                {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
              <Link to="/faq">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (<>
              <Link to="/home-chargers" className="footer-border pad-right pad-left">
                <FormattedMessage
                  id="homeChargers"
                  defaultMessage="Home Chargers"
                  description="Home Chargers Footer"
                  values={{
                    homeChargers: process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
              <Link to="/dealers" className="pad-right pad-left">
                <FormattedMessage
                  id="dealers"
                  defaultMessage="Dealers"
                  description="Dealers Footer"
                  values={{
                    dealers: process.env.REACT_APP_PAGES_DEALERS_TITLE
                  }}
                />
              </Link>
            </>) : (null)}
          </div>
          <div className="col-md-4 mb-3">
            <br />
          </div>
          <div className="col-md-4 mb-3">

          </div>

          <p style={{ textAlign: "center", marginBottom: 0, color: "#fff", fontSize: "11px", marginLeft:"-500px" }}>
            <span>&copy;</span>
            {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved.
          </p>
          <span className="disclaimer_container">
                  {/* <DisclaimerComponent clientName="zappynobackground"/> */}

                  <DisclaimerComponent
                    clientName="zappynobackground"
                    textStyle={{
                      fontFamily: "Source Sans Pro, Open Sans, Helvetica, sans-serif",
                      fontSize: "11px",
                      textAlign: "center"
                    }}
                    imageStyle={{ marginLeft: "451px",marginTop:"-33px" }}
                    wrapperStyle={{
                      padding: "0px",
                    }}
                  />
                </span>
        </div>
      </div>
    </>
  );
};

export default Footer;


