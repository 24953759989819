import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

import logo from "../../../assets/images/logo.png"
import "./Header.scss";
import { FormattedMessage } from 'react-intl';
// import { MAP_TABS } from '../../../../constants/mapTabs';
import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"

const Header = ({ page, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  const languageOptions = () => {
    return (
      <>
        <option
          value={'ES'}
          key={'ES'}
        >
          {'Spanish'}
        </option>
      </>
    )
  }

  const onChangeLanguage = e => {
    changeLanguage(e.target.value)
    SmoothScroll("root")
  }

  const displayVehiclesMainNavItem = (page) => {
    if (page === "vehicles") {
      return (
        <NavLink
          title={page === "vehicles" || "Compare Vehicles" || "Used Vehicles" ? "Active Page" : null}
          to="/vehicles"
          className="parent-nav"
        >
          <span>
            <FormattedMessage
              id="electricVehicles"
              defaultMessage="Electric Vehicles"
              description="Electric Vehicles Header"
            />
          </span>
        </NavLink>
      )
    } else if (page === "used-vehicles") {
      return (
        <NavLink
          title={page === "used-vehicles" ? "Active Page" : null}
          to="/used-vehicles"
          className="parent-nav"
        >
          <span>
            <FormattedMessage
              id="usedElectricVehicles"
              defaultMessage="Used Vehicles"
              description="Used Vehicles Header"
            />
          </span>
        </NavLink>
      )
    } else if (page === "compare-vehicles") {
      return (
        <NavLink
          title={page === "compare-vehicles" ? "Active Page" : null}
          to="/compare-vehicles"
          className="parent-nav"
        >
          <span>
            <FormattedMessage
              id="compareVehicles"
              defaultMessage="Compare Vehicles"
              description="Compare Vehicles Header"
            />
          </span>
        </NavLink>
      )
    } else {
      return (
        <NavLink
          title={page === "vehicles" ? "Active Page" : null}
          to="/vehicles"
          className="parent-nav"
        >
          <span>
            <FormattedMessage
              id="electricVehicles"
              defaultMessage="Electric Vehicles"
              description="Electric Vehicles Header"
            />
          </span>
        </NavLink>
      )
    }
  }



  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <div className="language-toggle">
          {process.env.REACT_APP_LANGUAGE_TOGGLE ?
            <select
              className="form-control select-dte"
              value={language}
              onChange={onChangeLanguage}
              aria-label="Choose a language"
            >
              <option defaultValue="EN" value={'EN'}>
                English
              </option>
              {languageOptions()}
            </select>
            : null}
        </div>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="/"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "116px" }}
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>Home</span>
                  </Link>
                </NavItem>
              ) : (null)}
              <NavItem className="parent-nav-item">
                {displayVehiclesMainNavItem(page)}

                <ul className="sub-nav">
                  {/* { page !== "vehicles"  ? ( */}
                  <NavItem>
                    <NavLink
                      title={page === "vehicles" ? "Active Page" : null}
                      to="/vehicles"
                    >
                      <span>
                        <FormattedMessage
                          id="electricVehicles"
                          defaultMessage="Electric Vehicles"
                          description="Electric Vehicles Header"
                        />
                      </span>
                    </NavLink>
                  </NavItem>
                  {/* ):(null)} */}

                  {/* { page !== "used-vehicles"  ? ( */}
                  <NavItem>
                    <NavLink
                      title={page === "vehicles" ? "Active Page" : null}
                      to="/used-vehicles"
                    >
                      <span>
                        <FormattedMessage
                          id="usedElectricVehicles"
                          defaultMessage="Used Vehicles"
                          description="Used Vehicles Header"
                        />
                      </span>
                    </NavLink>
                  </NavItem>
                  {/* // ):(null)} */}

                  {/* { page !== "compare-vehicles"  ? ( */}
                  <NavItem>
                    <NavLink
                      title={page === "compare-vehicles" ? "Active Page" : null}
                      to="/compare-vehicles"
                    >
                      <span>
                        <FormattedMessage
                          id="compareVehicles"
                          defaultMessage="Compare Vehicles"
                          description="Compare Vehicles Header"
                        />
                      </span>
                    </NavLink>
                  </NavItem>
                  {/* ):(null)} */}
                </ul>

              </NavItem>

              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              {/* { process.env.REACT_APP_PAGES_COMPARE_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)} */}
              { process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "map" ? "Active Page" : null}
                    to="/charging-stations"
                  >
                    <span>
                      <FormattedMessage
                        id="chargingStations"
                        defaultMessage="Charging Stations"
                        description="Charging Stations Header"
                      /></span>
                  </NavLink>
                </NavItem>
              ) : (null)}
              { process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ): (null)}

              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "map" ? "active" : ""}
                >
                  <span>Maps</span>
                </DropdownToggle>
                <DropdownMenu right>
                  {MAP_TABS.filter(tab => tab.isEnabled).map(tab => {
                    return (
                      <DropdownItem
                        key={`nav-item-${tab.id}`}
                        title={page === "map" ? "Active Page" : null}
                        tag={NavLink}
                        to={tab.url}
                      >
                        {tab.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown> */}
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ) : (null)}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "events" ? "Active Page" : null}
                    to="/events"
                  >
                    <span>Events</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "dealers" ? "Active Page" : null}
                    to="/dealers"
                  >
                    <span>
                      <FormattedMessage
                        id="dealers"
                        defaultMessage="Dealers"
                        description="Qualified Dealers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : (null)}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};